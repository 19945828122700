/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

iframe {
  pointer-events: none;
}

.zoom-in {
  animation: zoom-in 0.6s ease;
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(30, 138, 132); */
}

.bg-green-100 {
  background-color: #d1fae5 !important;
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6 !important;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0 !important;
}


/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(207, 207, 207);
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: rgb(235, 235, 235);
  border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: rgb(233, 233, 233);
} */